// Utility function to compare values
function compareValues(obj1, obj2) {
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return [...obj1].sort().join() === [...obj2].sort().join()
  } else {
    return obj1 === obj2
  }
}

const getDefaultState = () => ({
  categories: {},
  values: {},
  defaultValues: {},
})

export const useFilterStore = defineStore('filterStore', {
  state: () => getDefaultState(),

  getters: {
    // Generates a query object based on the selected and default values in categories and values
    getQuery:
      (state) =>
      (returnDefault = true) => {
        let query = {}

        const processCategory = (category) => {
          return Object.values(category)
            .sort((c1, c2) => c1.order - c2.order)
            .filter((item) => item.selected)
            .map((item) => item.value)
            .join(',')
        }

        const getDefaultValues = (category) => {
          return Object.keys(category)
            .filter((key) => category[key].isDefault)
            .join(',')
        }

        Object.keys(state.categories).forEach((key) => {
          const category = state.categories[key]
          const values = processCategory(category)
          const defaults = getDefaultValues(category)

          if (key.includes(',')) {
            const keysArray = key.split(',')
            const valuesArray = values.split(',')
            keysArray.forEach((subKey, index) => {
              query[subKey] = valuesArray[index]?.length > 0 ? valuesArray[index] : null
            })
          }

          if (values && (returnDefault || values != defaults)) {
            query[key] = values
          } else {
            query[key] = Object.entries(category).length === 0 ? false : null
          }

          if (query[key] == 'all') {
            query[key] = []
          }
        })

        Object.keys(state.values).forEach((key) => {
          const value = state.values[key]
          if (returnDefault || !compareValues(value, state.defaultValues[key])) {
            query[key] = value
          } else {
            query[key] = Array.isArray(value) ? [] : null
          }
        })

        return query
      },

    // Gets selected values of a given category type
    getSelected: (state) => (type) => {
      const category = state.categories[type]
      return category
        ? Object.keys(category)
            .filter((key) => category[key].selected)
            .join(',')
        : null
    },
  },

  actions: {
    // Initializes a category with given values and selected/default states
    init({ type, values, selected, defaultValue, atLeastOne }) {
      if (!this.categories[type]) {
        this.categories[type] = {}
      }
      // console.log('init', type, values, selected, defaultValue, atLeastOne)
      const category = this.categories[type]

      values.forEach((value, i) => {
        category[value] = {
          value,
          selected: selected == value || (Array.isArray(selected) && selected.find((x) => x == value)),
          isDefault: value == defaultValue,
          order: i,
        }
      })

      const selectedValues = Object.keys(category).filter((key) => category[key].selected)
      if (!selectedValues.length) {
        const defaultSelected = Object.keys(category).filter((key) => category[key].isDefault)
        if (defaultSelected.length) {
          category[defaultSelected[0]].selected = true
        } else if (atLeastOne) {
          category[values[0]].selected = true
        }
      }

      this.categories = { ...this.categories }
    },

    // Removes a category by type
    remove({ type }) {
      delete this.categories[type]
    },

    // Removes a value by type
    removeValue({ type }) {
      delete this.values[type]
    },

    // Toggles selection state of an option within a category
    toggle({ type, option, multiple, atLeastOne }) {
      let _type = this.categories[type]

      if (!_type) {
        _type = this.categories[type] = {}
      }

      let current = _type[option]

      if (!current) {
        current = _type[option] = {
          selected: false,
        }
      }

      if (multiple !== true) {
        if (_type[option].selected && atLeastOne === false) {
          Object.keys(_type).forEach((key) => (_type[key].selected = false))
        } else {
          Object.keys(_type).forEach((key) => (_type[key].selected = false))
          _type[option].selected = true
        }
      } else {
        _type[option].selected = !_type[option].selected
      }

      if (atLeastOne && !Object.keys(_type).filter((key) => _type[key].selected == true).length) {
        _type[option].selected = true
      }

      this.categories = { ...this.categories }
    },

    // Sets a value and optionally a default value for a type
    set({ type, value, defaultValue }) {
      this.values[type] = value
      if (defaultValue !== undefined) {
        this.defaultValues[type] = defaultValue
      }
      this.values = { ...this.values }
    },

    // Resets the state to default values
    reset() {
      Object.values(this.categories).forEach((category) => {
        Object.values(category).forEach((item) => {
          item.selected = item.isDefault
        })
      })

      Object.keys(this.values).forEach((key) => {
        this.values[key] = this.defaultValues[key]
      })

      this.categories = { ...this.categories }
    },
    resetState() {
      Object.entries(getDefaultState()).forEach(([key, value]) => {
        this[key] = value
      })
    },
  },
})
